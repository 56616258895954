import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { LocaleContext } from "context/locale-context";
import { keyframes } from "@emotion/core";

const pulsering = keyframes`
 0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
`;

const pulsedot = keyframes`
0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
`;

const PulsatingCircle = styled.div(props => ({
  position: "absolute",
  top: props.top,
  left: props.left,
  transform: "translateX(-50%) translateY(-50%)",
  width: "30px",
  height: " 30px",
  cursor: "pointer",
  zIndex: 1,
  "&::before": {
    content: "''",
    position: "relative",
    display: "block",
    width: "300%",
    height: "300%",
    boxSizing: "border-box",
    marginLeft: props.isRTL ? 0 : "-100%",
    marginRight: props.isRTL ? "-100%" : 0,
    marginTop: "-100%",
    borderRadius: "45px",
    backgroundColor: "#a53851",
    animation: `${pulsering} 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite`
  },
  "&::after": {
    content: "''",
    position: "absolute",
    left: 0,
    top: 0,
    display: "block",
    width: "100%",
    height: "100%",
    backgroundColor: "#a53851",
    borderRadius: "15px",
    boxShadow: "0 0 8px rgba(0,0,0,.3)",
    animation: `${pulsedot} 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite`
  }
}));

const Content = styled.div(props => ({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  opacity: props.num,
  transition: "opacity 0.6s",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: "rgba(34, 22, 37, 0.8)",
    zIndex: 3,
    opacity: props.num,
    transition: "opacity 0.4s"
  }
}));

const Figure = styled.figure({
  position: "relative",
  zIndex: 3,
  margin: 0,
  transition: "transform 0.6s, opacity 0.6s",
  "&::before": {
    content: '"×"',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "10px",
    right: "10px",
    color: "#EEE6F0",
    height: "36px",
    width: "36px",
    borderRadius: "50%",
    background: "rgba(68, 44, 74, 0.5)",
    fontSize: "24px",
    cursor: "pointer"
  }
});

function Hotspot({ hotspot, isRTL }) {
  const [num, setNum] = useState(0);
  const { image, left, top } = hotspot;
  return (
    <>
      <PulsatingCircle
        onClick={() => setNum(1)}
        top={top}
        left={left}
        isRTL={isRTL}
      ></PulsatingCircle>
      <Content num={num} onClick={() => setNum(0)}>
        <Figure onClick={() => setNum(0)}>
          <img src={image} />
        </Figure>
      </Content>
    </>
  );
}

function Hotspots({ src, hotspots }) {
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  return (
    <div
      css={{
        position: "relative",
        borderRadius: "2px",
        overflow: "hidden",
        zIndex: 1,
        boxShadow: "0 2px 4px #646464",
        marginBottom: "1.45rem"
      }}
    >
      <div css={{ marginBottom: 0 }}>
        <img src={src} />
      </div>

      {hotspots.map(hotspot => (
        <Hotspot hotspot={hotspot} key={hotspot.image} isRTL={isRTL} />
      ))}
    </div>
  );
}

Hotspots.propTypes = {
  src: PropTypes.string,
  hotspots: PropTypes.array
};

Hotspot.propTypes = {
  hotspot: PropTypes.object
};

export default Hotspots;
