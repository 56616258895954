import React, { useContext } from "react";
import showdown from "showdown";
import { ThemeContext } from "context/theme-context";

const converter = new showdown.Converter();

function TextWImg({ text, img }) {
  const { colors } = useContext(ThemeContext);

  return (
    <>
      <div css={{ display: "flex" }}>
        <div
          dangerouslySetInnerHTML={{ __html: converter.makeHtml(text) }}
          css={{
            width: "70%",
            "& a": {
              color: colors.primary,
              fontWeight: "bold"
            },
            "& p": {
              color: colors.dark,
              lineHeight: "26px"
            }
          }}
        />
        <div
          css={{ width: "30%", display: "flex", justifyContent: "flex-end" }}
        >
          <img src={img} width="70%" css={{ alignSelf: "flex-end" }} />
        </div>
      </div>
    </>
  );
}

export default TextWImg;
