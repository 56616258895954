import React, { useContext } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import styled from "@emotion/styled";
import MarkerClusterGroup from "react-leaflet-markercluster";
import sources from "../../../data/new-sources.json";
import { LocaleContext } from "context/locale-context";
import useTranslations from "hooks/useTranslations";
import { rhythm } from "utils/typography";

// Importing Control directly ends up with an error. This is a workaround.
// let Con;
// if (typeof window !== "undefined") {
//   console.log("hi");
//   import("react-leaflet-control").then((Control) => {
//     Con = Control.default;
//   });
// }

const LegendOption = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: rhythm(0.8),
});

function MapLegend({ filterOptions, setFilterOptions }) {
  const locale = useContext(LocaleContext);
  const tr = useTranslations();
  const isRTL = locale === "ar";
  return (
    <div
      css={{
        backgroundColor: "#fff",
        marginLeft: rhythm(1),
        padding: `${rhythm(1.2)} ${rhythm(1.2)}`,
        borderRadius: "20px",
        boxShadow: "0 2px 4px 0 rgba(101,101,101,0.5)",
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      <h3
        css={{
          margin: 0,
          marginBottom: rhythm(0.8),
        }}
      >
        {tr("Map Legend")}
      </h3>
      <LegendOption>
        <img src="/assets/map-marker-green.png" />
        <span>{tr("Greatest Reliability")}</span>
      </LegendOption>
      <LegendOption>
        <img src="/assets/map-marker-yellow.png" />
        <span>{tr("Average Reliability")}</span>
      </LegendOption>
      <LegendOption css={{ marginBottom: 0 }}>
        <img src="/assets/map-marker-red.png" />
        <span>{tr("Poor Reliability")}</span>
      </LegendOption>
    </div>
  );
}

function YemeniMap() {
  const tr = useTranslations();
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";

  const viewPort = {
    lat: 16,
    lng: 47,
    zoom: 6,
  };

  const position = [viewPort.lat, viewPort.lng];

  function createClusterCustomIcon(cluster) {
    return L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: "marker-cluster-custom",
      iconSize: L.point(40, 40, true),
    });
  }

  const clusterIcon = (reliability) => {
    if (reliability === 4) {
      return "/assets/map-marker-green.png";
    } else if (reliability === 3 || reliability === 2) {
      return "/assets/map-marker-yellow.png";
    } else {
      return "/assets/map-marker-red.png";
    }
  };

  return (
    <div
      css={{
        maxWidth: "90%",
        margin: "auto",
      }}
    >
      {typeof window !== "undefined" ? (
        <MapContainer
          center={position}
          zoom={viewPort.zoom}
          maxZoom={17}
          scrollWheelZoom={false}
          className="leaflet-map"
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png"
          />
          {sources.map((group) => {
            return (
              <MarkerClusterGroup
                key={group.index}
                iconCreateFunction={createClusterCustomIcon}
              >
                {group[1].map((city) => {
                  return (
                    <Marker
                      key={city.id}
                      position={{
                        lat: city.latitude,
                        lng: city.longitude,
                      }}
                      riseOnHover={true}
                      icon={L.icon({
                        iconUrl: clusterIcon(city.reliability),
                        iconAnchor: [8, 10],
                      })}
                    >
                      <Popup
                        css={{
                          direction: isRTL ? "rtl" : "ltr",
                          textAlign: isRTL ? "right" : "left",
                        }}
                      >
                        <div>
                          <span>{tr("Source Name")}</span> :{" "}
                          <span>{city.name}</span>
                        </div>
                        <div>
                          <span>{tr("Reliability")}</span> :{" "}
                          <span>{city.reliability}</span>
                        </div>
                        <div
                          css={{
                            direction: isRTL ? "rtl" : "ltr",
                          }}
                        >
                          <span>{tr("Still Active")}</span> :
                          <span>
                            {city.isActive === "Y" ? tr("Yes") : tr("No")}
                          </span>
                        </div>
                      </Popup>
                    </Marker>
                  );
                })}
              </MarkerClusterGroup>
            );
          })}
          <Con position="bottomleft">
            <MapLegend />
          </Con>
        </MapContainer>
      ) : null}
    </div>
  );
}

export default YemeniMap;
